<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2024-{{ new Date().getFullYear() }}
        <a href="#"><strong>WebStack</strong></a>
        design by <a href="https://devttl.cn" target="_blank"><strong>开发生存时间</strong></a>
      </div>

      <div class="go-up">
        <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备14008887号-2</a> | <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>
